import { useState, useMemo } from "react"
import { StringValueType } from "src/api/graphql/graphql"
import { Button } from "src/ui/Button/Button"
import styled, { css } from "styled-components"
import { Tag } from "src/ui/Tag"
import { ParamHistory, ParamTypes } from "src/Expectation/Expectation"
import { addDynamicStyledText } from "src/Expectation/utils/addDynamicStyledText"
import { formatNumber } from "src/common/utils/formatNumber"
import { Param, UpdatedParam } from "src/ui/Param/Param"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { ExpandableContainer } from "src/ui/ExpandableContainer"

const StyledExpectation = styled.div<{ $isExpectationDeleted?: boolean; $isDeletionAction?: boolean }>`
  ${({ $isDeletionAction }) => css`
    ${$isDeletionAction &&
    css`
      text-decoration: line-through;
    `}
  `}
`

const StyledExpectationWithCodeBlock = styled(StyledExpectation)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
`

const SeeAllButton = styled(Button)`
  display: inline-flex;
`

const CodeBlockContainer = styled(ExpandableContainer)`
  width: 100%;
  & > header {
    background: rgb(0 0 0 / 2%);
    padding: 12px 16px;
    & > div > div > span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  & > div {
    padding: ${({ expanded }) => (expanded ? "16px" : "0")};
  }
`

function formatStringNumber(input: string): boolean {
  if (isNaN(Number(input)) || input.length > 16) {
    return true
  }
  return false
}

const formatLaguage = (key: string): string => {
  switch (key) {
    case "sql":
      return "SQL"
    case "python":
      return "Python"
    case "yaml":
      return "YAML"
    case "json":
      return "JSON"
    default:
      return key
  }
}

// TODO: template should be populated with description, but currently is not
// when it is, we can remove this function
const parseTemplate = (template: string | null | undefined, kwargs: string | null | undefined) => {
  if (!formatStringNumber(String(template))) {
    template = formatNumber(String(template))
  }

  if (template) {
    return template
  }

  return JSON.parse(kwargs ?? '{"description": "no description"}')?.description
}

function parseCodeBlock(codeBlock: StringValueType["codeBlock"]) {
  const result = JSON.parse(codeBlock ?? "{}")
  return {
    lng: result?.language ?? "",
    tmpl: result?.code_template_str ?? "",
  }
}

function parseParams(paramsJson: StringValueType["params"]) {
  const params: ParamTypes = JSON.parse(paramsJson ?? "{}")
  return params
}

function replaceTemplateParams(template: string, params: ParamTypes) {
  return template.replace(
    /**
     * \$ - matches $ literally
     * (\w+) - matches one or more word characters (alphanumeric or underscore)
     * g - global flag to match all occurrences
     */
    /\$(\w+)/g,
    // for each matched group, replace with corresponding param if present
    (match, key: string) => {
      let param = params[key]?.value
      if (Array.isArray(param)) {
        param = param.join(", ")
      }
      return param ?? match // If no matching param, leave as is
    },
  )
}

type RenderCodeBlockProps = {
  template: StringValueType["template"]
  codeBlock: StringValueType["codeBlock"]
  params: ParamTypes
}
function RenderCodeBlock({ codeBlock, params, template }: RenderCodeBlockProps) {
  const [open, setOpen] = useState(true)

  // only run json.parse once per codeblock string if possible
  const { language, value } = useMemo(() => {
    const { lng, tmpl } = parseCodeBlock(codeBlock)

    const result = replaceTemplateParams(tmpl, params)

    return {
      language: lng,
      value: result,
    }
  }, [codeBlock, params])

  if (!language && !value) return null

  return (
    <>
      <CodeBlockContainer
        title={`${formatLaguage(language)} Code`}
        collapsible
        background="colorFillDark"
        minWidth="100%"
        onToggle={() => setOpen((c) => !c)}
        expanded={open}
      >
        {open && (
          <CodeSnippetEditor
            name={template?.replace(/\s/g, "-")}
            language={language}
            value={value ?? ""}
            readOnly={true}
            minLines={1}
            maxLines={20}
            fontSize={14}
            showLineNumbers={false}
            showGutter={false}
          />
        )}
      </CodeBlockContainer>
    </>
  )
}

const curryDynamicTagRenderer = (
  evrConfig?: { danger?: boolean },
  isExpectationDeleted?: boolean,
  isDeletionAction?: boolean,
) =>
  function RenderDynamicTextTag(value: string | number | string[], id?: string, history?: ParamHistory) {
    let newValue = value
    if (typeof value === "number") {
      newValue = formatNumber(value)
    }
    if (evrConfig?.danger !== undefined) {
      return (
        <Tag key={id} $danger={evrConfig.danger}>
          {newValue}
        </Tag>
      )
    }
    if (history || isExpectationDeleted || isDeletionAction) {
      return (
        <UpdatedParam
          key={id}
          $isParamDeleted={history?.removed}
          $isParamAdded={history?.added}
          $isOnDarkBackground={isExpectationDeleted}
          $isDeletionAction={isDeletionAction}
        >
          {newValue}
        </UpdatedParam>
      )
    }
    return <Param key={id}>{newValue}</Param>
  }

type RenderStringProps = {
  template: string | null | undefined
  params: ParamTypes
  codeBlock: StringValueType["codeBlock"]
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  evrConfig?: { danger?: boolean }
  isPreview?: boolean
}
function RenderString({
  template,
  params,
  codeBlock,
  isExpectationDeleted,
  isDeletionAction,
  evrConfig,
  isPreview,
}: RenderStringProps) {
  const [truncate, setTruncate] = useState<boolean>(
    Object.keys(params).length > 10 || (params.column_list?.value?.length ?? 0) > 7,
  )

  if (!template) return null

  return (
    <>
      {addDynamicStyledText({
        template: template,
        params,
        getDynamicStyleTag: curryDynamicTagRenderer(evrConfig, isExpectationDeleted, isDeletionAction),
        truncate,
        isExpectationDeleted,
        isDeletionAction,
      })}

      {truncate && (
        <SeeAllButton size="small" type="text" onClick={() => setTruncate(false)}>
          See all
        </SeeAllButton>
      )}

      {codeBlock && !isPreview && <RenderCodeBlock codeBlock={codeBlock} params={params} template={template} />}
    </>
  )
}

type StringRenderComponentProps = {
  value: StringValueType
  evrConfig?: { danger?: boolean }
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
  kwargs?: string | null
  isPreview?: boolean
}

function StringRenderComponent({
  value,
  evrConfig,
  isExpectationDeleted,
  isDeletionAction,
  kwargs,
  isPreview,
}: StringRenderComponentProps) {
  const { params: stringParams, codeBlock } = value
  if (typeof stringParams !== "string") {
    throw new Error("type error: unable to parse stringParams. expected string, got " + typeof stringParams)
  }
  // don't run json.parse on every render cycle if we still have the same string
  const params = useMemo(() => parseParams(stringParams), [stringParams])

  const template = useMemo(() => parseTemplate(value.template, kwargs), [value.template, kwargs])

  const Wrapper = codeBlock ? StyledExpectationWithCodeBlock : StyledExpectation

  return (
    <Wrapper aria-label="Expectation summary" $isDeletionAction={isDeletionAction}>
      <RenderString
        template={template}
        params={params}
        codeBlock={codeBlock}
        evrConfig={evrConfig}
        isExpectationDeleted={isExpectationDeleted}
        isDeletionAction={isDeletionAction}
        isPreview={isPreview}
      />
    </Wrapper>
  )
}

export { StringRenderComponent }
