import { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { JsonFormsUISchemaRegistryEntry, UISchemaElement } from "@jsonforms/core"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

export const WindowedFixedValuesUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "min_value" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "Min Value",
        scope: "#/properties/min_value",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_min",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_min.description,
          style: {
            marginBottom: "24px",
          },
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        scope: "#/properties/max_value",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          style: {
            width: "100%",
          },
        },
        layoutProps: {
          columns: 8,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict_max",
        formItemProps: {
          tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_max.description,
        },
        layoutProps: {
          columns: 4,
        },
      },
    ],
  } satisfies UISchema<
    (typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[0]
  > as UISchemaElement,
}

export const WindowedRangeWithOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (schemaPath === "#/properties/to_be_between" && schema.properties && "offset" in schema.properties) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/offset",
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonAfter: "%",
        },
        layoutProps: {
          columns: 4,
        },
      },
      {
        type: "Control",
        scope: "#/properties/range",
        options: {
          addonAfter: "runs",
        },
        layoutProps: {
          columns: 13,
        },
      },
      {
        type: "Control",
        scope: "#/properties/strict",
        layoutProps: {
          columns: 3,
        },
      },
    ],
  } satisfies UISchema<
    (typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]
  > as UISchemaElement,
}

export const WindowedRangeWithFixedOffsetUISchemaRegistry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath) => {
    if (
      schemaPath === "#/properties/to_be_between" &&
      schema.properties &&
      !("offset" in schema.properties) &&
      !("mostly" in schema.properties)
    ) {
      return 100
    }
    return -1
  },
  uischema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        label: "",
        scope: "#/properties/percent",
        options: {
          addonBefore: "-",
          addonAfter: "%",
        },
        layoutProps: {
          columns: 6,
        },
      },
      {
        type: "Control",
        scope: "#/properties/range",
        options: {
          addonAfter: "% of null values",
        },
        layoutProps: {
          columns: 18,
        },
      },
    ],
  } satisfies UISchema<
    (typeof jsonSchemas.expectColumnMinToBeBetween.schema.properties.to_be_between.oneOf)[1]
  > as UISchemaElement,
}
