// consolidating strings here to ensure language is consistent

import { EXPECTATION_CHANGE_LOG_LIMIT } from "src/common/config"

export const EXPECTATION_ERROR_TEXT = {
  EMPTY: "The configuration cannot be empty.",
  INVALID_JSON: "The configuration must have valid JSON syntax.",
  MISSING_KWARGS: "The configuration must have a 'kwargs' key.",
  UNEXPECTED_FIELD: "The configuration may only have 'kwargs' and 'meta' keys.",
  UNEXPECTED_ERROR:
    "An unexpected error occurred when attempting to update this Expectation. Try again or contact support.",
}

export const EDITOR_DRAWER_TITLE = "Edit Expectation"
export const EDITOR_INFO_TITLE = "Expectation JSON Editor"
export const EDITOR_INFO_TEXT = "Make changes to your Expectation's configuration:"
export const EDITOR_INFO_META_NOTE_TEXT = "Meta notes can be removed by leaving notes empty"
export const EDITOR_SUCCESS_MESSAGE = "Expectation updated successfully"

export enum GroupName {
  SQL = "Custom SQL Expectations",
  TABLE = "Table level Expectations",
  UNKNOWN = "Unknown Expectation Type",
}

export const getUnexpectedValuesTableFooterText = (
  recordCount: number,
  totalRecordCount: number,
  allowCopyUnexpectedIndexQuery: boolean,
): string =>
  allowCopyUnexpectedIndexQuery
    ? `Showing first ${recordCount} of ${totalRecordCount} unexpected values. Copy query to retrieve all unexpected values.`
    : `Showing first ${recordCount} of ${totalRecordCount} unexpected values.`

export const UNEXPECTED_VALUES_COPY_QUERY_TEXT = "Copy query to retrieve all unexpected values"
export const UNEXPECTED_VALUES_COPY_QUERY_DISABLED_TEXT =
  'Enable "return_unexpected_index_query" in Checkpoint result format to copy the query'

export const SET_ORG_NAME_ERROR_TEXT =
  "An error occurred when attempting to set your organization name. Try again or contact support."

export const NO_EXPECTATIONS_TOOLTIP_TEXT = "Add at least one Expectation to start validating your Data Asset"

export const EXPECTATION_CHANGE_LOG_LIMIT_TEXT = `Showing the previous ${EXPECTATION_CHANGE_LOG_LIMIT} Expectation changes.`
export const HISTORY_LOG_ERROR_MESSAGE = "There was a problem loading the Expectations Change Log"
export const EXPECTATIONS_LIST_ERROR_MESSAGE = "There was a problem loading the Expectations"

export const UPDATE_EXPECTATION_ERROR = "Failed to update Expectation"
